import React from 'react';
import Footer from "../../components/ui/Footer/Footer";
import Header from "../../components/ui/Header/Header";
import SectionLayout from "../../layouts/SectionLayout/SectionLayout";

import styles from "./InDevelopmentPage.module.scss"

const InDevelopmentPage = () => {
  return (
    <div className={styles.inDevelopmentPage}>
      <Header/>

      <SectionLayout>
        <h1 className={styles.errorMessage}>
          Упс...<br/>
        </h1>

        <h2 className={styles.inDevMode}>
          Кажется эта страница находится еще находится в разработке
        </h2>

        <p className={styles.contactUs}>
          Но вы можете уточнить интересующие вас вопросы по этому направлению, позвонив по
          номеру: <br/>
        </p>
        <p className={styles.phone}>+7 906 100-60-10</p>
      </SectionLayout>

      <SectionLayout>
        <Footer/>
      </SectionLayout>
    </div>
  );
};

export default InDevelopmentPage;