export const Offices = [
  {
    address: "г. Уфа, ул. Менделеева 219",
    phoneNumber: "+7 (347) 246-6-246",
    email: "ufa@sbis.pro"
  },{
    address: "г. Уфа, ул. Революционная 173",
    phoneNumber: "+7 (347) 246-6-246",
    email: "ufa@sbis.pro"
  },{
    address: "г. Санкт-Петербург, ул. Малая Морская 16",
    phoneNumber: "+7 (812) 241-1-246",
    email: "spb@sbis.pro"
  },{
    address: "г. Екатеринбург, ул. Мамина-Сибиряка 40",
    phoneNumber: "+7 (343) 226-46-82",
    email: "ekb@sbis.pro"
  },{
    address: "г. Пермь, ул. Окулова 75, к. 2",
    phoneNumber: "+7 (342) 264-02-63",
    email: "perm@sbis.pro"
  },{
    address: "г. Казань, ул. Баумана, д. 82",
    phoneNumber: " +7 (843) 207-05-04",
    email: "kazan@sbis.pro"
  },{
    address:   "г. Симферополь, ул. Александра Невского 16",
    phoneNumber: " +7 (906) 100-60-10",
    email: "krym@sbis.pro"
  },
]