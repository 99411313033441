import React, {useEffect} from 'react';

interface PageProps {
  title: string;
  children: React.ReactNode;
}

const Page = ({title, children}: PageProps) => {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return (
    <>
      {children}
    </>
  );
};

export default Page;