export const routes = {
  // main routes
  homePage: "/",
  directionsPage: "/napravleniya",
  tariffsPage: "/tarify",
  marketPage: "/market",
  helpPage: "/podderzhka-24-na-7",

  // directions routes
  reportPage: "/otchetnost-cherez-internet",
  accountingPage: "/buhgalteriya-i-uchet",
  crmPage: "/crm-dlya-activnih-prodazh",
  edoPage: "/elektronnyj-dokumentooborot",
  kaEdoPage: "/edo-s-kontragentami",
  ediPage: "/zakazy-i-postavki-edi",
  controlBusinessProccessesPage: "/upravlenie-biznes-processami",
  internationalEdoPage: "/mezhdunarodnyj-edo",
  eplPage: "/Electronnie-pytevie-listi",
  eTrNPage: "/ETrN-i-perevosochnie-dokumenti",
  ofdPage:"/ofd",
  automationBusinessPage:"/automation-bisness",
  marketAndOnlineSalePage:"/market-i-online-prodazhi",
  restaurantCafeBistroPage:"/restarani-cafe-i-stolovie",
  salonAndServiceSectorPage:"/saloni-i-sfera-yslug",
  tradeAndProductionPage:"/torgovla-i-proizvodstvo",
  mobileStaffPage:"/mobilnie-sotrydniki",
  videoControlPage:"/videonablydenie",
  exchangeWithGovPage:"/exchange-with-government-systems",
  cloudTelephonyPage:"/oblachnaya-telefoniya",
  aboutCompaniesPage:"/vse-o-kompaniyah",
  tendersAndPurchasesPage:"/torgi-i-zakypki",
  personalControlPage:"/ypravlenie-personalom",
  personalEDOPage:"/kadrovi-edo",
  salaryAndPersonnelPage:"/zarplati-i-kadri",
  militaryRegistrationPage:"/voinskii-ychet",
  controlWorkingTime:"/ychet-rabochego-vremeni",
  recruitmentOfPersonnelPage:"/podbor-personala",
  kpiAndMotivationPage:"/kpi-i-motivacia",
  corporatePortalPage:"/korporativnii-portal",
  messengerPage:"/messenger",
  webinarsAndCommunicationsPage:"/webinari-i-kommunikacii",
  remoteControlPage:"/ydalennii-dostyp",

  // ecp routes
  ecpPage: "/elektronnaya-podpis",
  mchdPage: "/mashinochitaemye-doverennosti",
  corpUcPage: "/korporativnyj-uc",
  logisticsPage: "/transport-i-logistika",
}