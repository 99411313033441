import {routes} from "../../../router/routes";

export const columns = [
  {
    title: "Сервисы",
    columnItems: [
      {
        name: "Бухгалтерия и учет",
        link: routes.accountingPage
      }, {
        name: "Отчетность через интернет",
        link: routes.reportPage
      }, {
        name: "ЭДО, EDI и бизнес процессы",
        link: routes.edoPage
      }, {
        name: "Всё о компаниях и владельцах",
        link: routes.aboutCompaniesPage
      }, {
        name: "Торги и закупки",
        link: routes.tradeAndProductionPage
      },
    ]
  }, {
    title: "Автоматизация бизнеса",
    columnItems: [
      {
        name: "ОФД",
        link: routes.ofdPage
      }, {
        name: "Автоматизация бизнеса",
        link: routes.automationBusinessPage
      }, {
        name: "Маркировка",
        link: routes.exchangeWithGovPage
      }, {
        name: "Управление персоналом",
        link: routes.personalControlPage
      }, {
        name: "Корпоративный портал",
        link: routes.corporatePortalPage
      },
    ]
  }, {
    title: "Полезное",
    columnItems: [
      {
        name: "CRM",
        link: routes.crmPage
      }, {
        name: "Электронная подпись",
        link: routes.ecpPage
      }, {
        name: "Транспорт и логистика",
        link: routes.logisticsPage
      }, {
        name: "Удаленный доступ",
        link: routes.remoteControlPage
      },
    ]
  }, {
    title: "О компании",
    columnItems: [
      {
        name: "Контакты",
        link: routes.helpPage
      }, {
        name: "Политика конфиденциальности",
        link: "https://online.sbis.ru/shared/disk/7bb47deb-7cc4-4394-bb87-215b510c9c01"
      },
    ]
  },
]

