import React from 'react';


import styles from "./ContactUsModal.module.scss"

import LogoImage from "../../../assets/images/logos/logo.svg"

interface ModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const ContactUsModal = ({active, setActive}: ModalProps) => {
  return (
    <>
      <div className={active ? styles.modal + " " + styles.active : styles.modal} onClick={() => setActive(false)}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <div className={styles.logoBox}>
            <img src={LogoImage} alt="ЦЕНТР ВНЕДРЕНИЯ | СБИС" className={styles.logo}/>
          </div>

          <h2 className={styles.title}>Оставить заявку</h2>

          <div className={styles.inputs}>
            <input type="text" placeholder={"Имя *"} className={styles.input} id={"name"}/>
            <input type="number" placeholder={"Телефон *"} className={styles.input} id={"phone-number"}/>
            <input type="text" placeholder={"Название компании"} className={styles.input} id={"company-name"}/>
            <input type="text" placeholder={"Сообщение"} className={styles.input} id={"message"}/>
          </div>

          <div className={styles.personalDataAgree}>
            <input type="checkbox" id={"personalDataAgreementCh"} name={"personalDataAgreementCh"} value={"off"}
                   className={styles.input}/>
            <p className={styles.text}>Я соглашаюсь на <span
              onClick={() => window.location.href = "https://online.sbis.ru/shared/disk/7bb47deb-7cc4-4394-bb87-215b510c9c01"}
              className={styles.agreementLink}>обработку персональных данных</span>
            </p>
          </div>

          <div className={styles.btnBox}>
            <button type={"submit"} className={styles.btn}>Отправить</button>
          </div>
        </div>
      </div>
    </>

  );
};

export default ContactUsModal;