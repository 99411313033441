import React from 'react';

import styles from "./SpinnerLoader.module.scss"

const SpinnerLoader = () => {
  return (
    <div className={styles.spinnerLoader}>
      <span className={styles.loader}></span>
    </div>
  );
};

export default SpinnerLoader;