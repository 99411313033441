import React from 'react';

import styles from "./Partners.module.scss"
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules"
import {partnersData} from "./PartnersData";
import DzenPlayer from "../../ui/DzenPlayer/DzenPlayer";
import SectionTitle from "../../ui/SectionTitle/SectionTitle";


import "swiper/css"

const Partners = () => {
  return (
    <div className={styles.partnersBlock}>
      <SectionTitle title={"В СБИС уже работают"}/>

      <Swiper
      direction={'horizontal'}
      slidesPerView={1}
      className={styles.swiper}
      loop={true}
      modules={[Autoplay]}
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
      }}
      speed={2000}
      >
        {partnersData.map((partner, index) => (
          <SwiperSlide className={styles.partnerSlide}>
            <div className={styles.columns}>
              <div className={styles.playerColumn}>
                <DzenPlayer url={partner.videoID}/>
              </div>
              <div className={styles.textColumn}>
                <div className={styles.textWrapper}>
                  <h1 className={styles.companyName}>{partner.companyName}</h1>
                  <p className={styles.reviewText}>{partner.reviewText}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Partners;