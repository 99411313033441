
export const NavItems = [
  {
    linkTo: "/napravleniya",
    text: "Направления"
  },
  {
    linkTo: "/tarify",
    text: "Тарифы"
  },
  {
    linkTo: "/market",
    text: "Маркет"
  },
  {
    linkTo: "/podderzhka-24-na-7",
    text: "Поддержка 24/7"
  },
]