import React, {Suspense} from 'react';

import styles from "./SectionLayout.module.scss"

interface IProps {
  children: React.ReactNode
}

const SectionLayout = ({children}: IProps) => {
  return (
    <section className={styles.container}>
      <Suspense>
        {children}
      </Suspense>
    </section>
  );
};

export default SectionLayout;