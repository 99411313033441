import React from 'react';

import styles from './DirectionCard.module.scss'

import RightArrow from "../../../assets/images/icons/right-arrow.svg"

interface DirectionCardProps {
  icon?: any;
  name: string;
  about: string;
  link: string;
}

const DirectionCard = ({icon, name, about, link}: DirectionCardProps) => {
  return (
    <div className={styles.directionCard} onClick={() => window.location.href = link}>
      <div className={styles.cardContent}>
        <div className={styles.icons}>
          {<img src={icon} alt={name} className={styles.icon}/>}
          <img src={RightArrow} alt={"Подробнее"} className={styles.icon}/>
        </div>

        <div className={styles.textWrapper}>
          <h2 className={styles.title}>{name}</h2>
          <p className={styles.text}>{about}</p>
        </div>
      </div>
    </div>
  );
};

export default DirectionCard;