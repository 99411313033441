import React from 'react';

interface DzenPlayerProps {
  url: string
}

const DzenPlayer = ({url}: DzenPlayerProps) => {
  return (
    <iframe width="100%" height="100%"
            src={'https://dzen.ru/embed/' + url + '?from_block=partner&from=zen&mute=0&autoplay=0&tv=0'}
            allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
            frameBorder="0" scrolling="no" allowFullScreen title={"1"}></iframe>
  );
};

export default DzenPlayer;