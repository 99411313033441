import React from 'react';

import styles from "./JoinUs.module.scss"
import MainButton from "../../ui/MainButton/MainButton";
import SabyImage from "../../../assets/images/saby.png"

const JoinUs = () => {
  return (
    <div className={styles.joinUsBlock}>
      <div className={styles.content}>
        <div className={styles.columns}>
          <div className={styles.column}>
            <img src={SabyImage} alt="Картинка" className={styles.imageItem}/>
          </div>


          <div className={styles.column}>
            <div className={styles.textWrapper}>
              <h1 className={styles.title}>Присоединяйтесь к нам прямо сейчас</h1>

              <p className={styles.text}>Наше решение пользуются более чем 1 000 000 компаний всех размеров и
                направлений бизнеса, начиная от небольших стартапов и заканчивая международными корпорациями. Мы успешно
                справляемся с задачами, стоящими перед каждым клиентом, будь то казино или церковь, детский сад или
                министерство.</p>

              <MainButton/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;