import React, {useState} from 'react';
import ContactUsModal from "../ContactUsModal/ContactUsModal";

import styles from './MainButton.module.scss'
import icon from './icon.svg'



const MainButton = () => {
  const [modalActive, setModalActive] = useState(false);

    return (
      <>
        <button className={styles.mainButton} onClick={() => setModalActive(!modalActive)}>
          <img src={icon} alt="Иконка" className={styles.icon}/>
          <p className={styles.buttonText}>
            Оставить заявку
          </p>
        </button>
        <ContactUsModal active={modalActive} setActive={setModalActive}/>
      </>
    );
};

export default MainButton;