import React from 'react';
import {Link} from "react-router-dom";
import {Offices} from "../../../lib/data/OfficesData";

import styles from './Footer.module.scss'

import VKLogo from "../../../assets/images/logos/vk.svg"
import WhatsappLogo from "../../../assets/images/logos/whatsapp.svg"
import TelegramLogo from "../../../assets/images/logos/telegram.svg"


import {columns} from './FooterItems';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.columns}>
        {columns.map((column, index) => (
          <div className={styles.column}>
            <div className={styles.columnTitle}>{column.title}</div>

            <div className={styles.columnItems} id={index.toString()}>
              {column.columnItems.map((item, index) => (
                <Link to={item.link} className={styles.columnItem}>{item.name}<br/></Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.contactsColumns}>
        <div className={styles.contactColumn}>
          <div className={styles.addresses}>
            {Offices.map((address, index) => (
              <p className={styles.address}>{address.address}</p>
            ))}
          </div>
        </div>

        <div className={styles.contactColumn}>
          <p className={styles.phoneNumber}>8 (906) 100-60-10</p>
        </div>

        <div className={styles.contactColumn}>
          <div className={styles.socials}>
            <Link to={"https://vk.com/sbis_pro"} className={styles.social}>
              <img src={VKLogo} alt="Вконтакте"
                   className={styles.socialIcon}/>
            </Link>

            <Link to={"https://chat.whatsapp.com/FM7uLcGDxruJXXsWgbnQe2"} className={styles.social}>
              <img src={WhatsappLogo} alt="whatsapp" className={styles.socialIcon}/>
            </Link>

            <Link to={"https://t.me/sbisPRO"} className={styles.social}>
              <img src={TelegramLogo} alt="телеграм"
                   className={styles.socialIcon}/>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.remark}>
        <h1 className={styles.companyName}>© сбис | центр внедрения 2010-2024 </h1>

        <h1 className={styles.remarkText}>
          © "СБИС | ЦЕНТР ВНЕДРЕНИЯ". Администрация Сайта не несет ответственности за размещаемые Пользователями
          материалы (в т.ч. информацию и изображения), их содержание и качество.
        </h1>
      </div>
    </div>
  );
};

export default Footer;