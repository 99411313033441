import React, {lazy, Suspense} from 'react';
import {Route, Routes} from "react-router-dom";
import SpinnerLoader from "./components/ui/SpinnerLoader/SpinnerLoader";
import Page from "./layouts/Page/Page";
import DirectionsPage from "./pages/DirectionsPage/DirectionsPage";
import InDevelopmentPage from "./pages/InDevelopmentPage/InDevelopmentPage";
import {routes} from "./router/routes"


const HelpPage = lazy(() => import("./pages/HelpPage/HelpPage"));
const CloudCalls = lazy(() => import("./pages/CloudCalls/CloudCalls"));
const KedoPage = lazy(() => import("./pages/KedoPage/KedoPage"))
const RestaurantAutomation = lazy(() => import("./pages/RestaurantAutomationPage/RestaurantAutomation"))
const HomePage = lazy(() => import("./pages/HomePage/HomePage"))
const OfdPage = lazy(() => import("./pages/OfdPage/OfdPage"));
const ReportPage = lazy(() => import("./pages/ReportPage/ReportPage"));
const AccountingPage = lazy(() => import("./pages/AccountingPage/AccountingPage"));
const EdoPage = lazy(() => import("./pages/EdoPage/EdoPage"));
const EdiPage = lazy(() => import("./pages/EdiPage/EdiPage"));
const BusinessProcessesPage = lazy(() => import("./pages/BusinessProcessesPage/BusinessProcessesPage"))
const EcpPage = lazy(() => import("./pages/EcpPage/EcpPage"));
const BeautyAutomationPage = lazy(() => import("./pages/BeautyAutomationPage/BeautyAutomationPage"))
const ProductionAndTradePage = lazy(() => import("./pages/ProductionAndTradePage/ProductionAndTradePage"))
const MobileStaffPage = lazy(() => import("./pages/MobileStaffPage/MobileStaffPage"))
const ExchangeWithGovPage = lazy(() => import("./pages/ExchangeWithGovPage/ExchangeWithGovPage"));
const CrmPage = lazy(() => import("./pages/CrmPage/CrmPage"))
const AboutCompanies = lazy(() => import("./pages/AboutCompanies/AboutCompanies"))
const TendersAndPurchasesPage = lazy(() => import("./pages/TendersAndPurchasesPage/TendersAndPurchasesPage"))
const VideoControlPage = lazy(() => import("./pages/VideoControlPage/VideoControlPage"))
const PersonalControlPage = lazy(() => import("./pages/PersonalControlPage/PersonalControlPage"))
const ControlWorkingTimePage = lazy(() => import("./pages/ControlWorkingTimePage/ControlWorkingTimePage"))
const LogisticsPage = lazy(() => import("./pages/LogisticsPage/LogisticsPage"))
const CorporatePortalPage = lazy(() => import("./pages/CorporatePortalPage/CorporatePortalPage"))
const EplPage = lazy(() => import("./pages/EplPage/EplPage"))
const BusinessAutomationPage = lazy(() => import("./pages/BusinessAutomationPage/BusinessAutomationPage"))
const WebinarsPage = lazy(() => import('./pages/WebinarsPage/WebinarsPage'))
const EtrnPage = lazy(() => import("./pages/EtrnPage/EtrnPage"))
const MchdPage = lazy(() => import("./pages/MchdPage/MchdPage"))
const MilitaryAccountingPage = lazy(() => import("./pages/MilitaryAccountingPage/MilitaryAccountingPage"))
const SalaryAndStaffPage = lazy(() => import("./pages/SalaryAndStaffPage/SalaryAndStaffPage"))
const InternationalPage = lazy(() => import("./pages/InternationalPage/InternationalPage"))
const KpiPage = lazy(() => import("./pages/KpiPage/KpiPage"))
const RecruitmentPage = lazy(() => import("./pages/RecruitmentPage/RecruitmentPage"))
const OnlineMagazinePage = lazy(() => import('./pages/OnlineMagazinePage/OnlineMagazinePage'))
const EdoContractors = lazy(() => import('./pages/EdoContractorsPage/EdoContractorsPage'))

const App = () => {
  return (
    <Suspense fallback={<SpinnerLoader/>}>
      <Routes>
        <Route path={routes.homePage}
               element={<Page title={"Saby - Комплексная автоматизация бизнеса"}><HomePage/></Page>}/>
        <Route path={routes.reportPage}
               element={<Page title={"Saby - Электронная отчетность через интернет"}><ReportPage/></Page>}/>
        <Route path={routes.accountingPage} element={<Page
          title={"Saby Бухгалтерия - Сервис онлайн-бухгалтерии для бизнеса"}><AccountingPage/></Page>}/>
        <Route path={routes.edoPage}
               element={<Page title={"Saby - Электронный документооборот (ЭДО)"}><EdoPage/></Page>}/>
        <Route path={routes.ediPage} element={<Page title={"Saby - Заказы и поставки (EDI)"}><EdiPage/></Page>}/>
        <Route path={routes.controlBusinessProccessesPage} element={<Page
          title={"Saby Управление бизнес-процессами | Электронные согласования, настройка своих процессов без программирования по стандартам BPM"}><BusinessProcessesPage/></Page>}/>
        <Route path={routes.ecpPage}
               element={<Page title={"Купить ЭЦП | Получить электронную подпись в УЦ"}><EcpPage/></Page>}/>
        <Route path={routes.ofdPage} element={<Page title={"ОФД Saby - Оператор фискальных данных"}><OfdPage/></Page>}/>
        <Route path={routes.restaurantCafeBistroPage} element={<Page
          title={"Saby Presto - Программа для автоматизации ресторана, кафе, общепита"}><RestaurantAutomation/></Page>}/>
        <Route path={routes.salonAndServiceSectorPage} element={<Page
          title={"Saby Салон - Программа для автоматизации салон красоты"}><BeautyAutomationPage/></Page>}/>
        <Route path={routes.tradeAndProductionPage}
               element={<Page title={"Складской учет Saby: склад, закупки, продажи"}><ProductionAndTradePage/></Page>}/>
        <Route path={routes.mobileStaffPage}
               element={<Page title={"Saby - Мобильные сотрудники"}><MobileStaffPage/></Page>}/>
        <Route path={routes.videoControlPage}
               element={<Page title={"Saby - Видеонаблюдение"}><VideoControlPage/></Page>}/>
        <Route path={routes.exchangeWithGovPage}
               element={<Page title={"Saby - Обязательная маркировка товаров"}><ExchangeWithGovPage/></Page>}/>
        <Route path={routes.crmPage} element={<Page title={"Saby CRM для активных продаж"}><CrmPage/></Page>}/>
        <Route path={routes.aboutCompaniesPage} element={<Page
          title={"Saby Profile | Выписки ЕГРЮЛ и ЕГРИП, проверка контрагентов, ИНН и КПП организаций, реквизиты ИП и ООО"}><AboutCompanies/></Page>}/>
        <Route path={routes.tendersAndPurchasesPage} element={<Page
          title={"Saby - Поиск тендеров и закупок по всем площадкам в России"}><TendersAndPurchasesPage/></Page>}/>
        <Route path={routes.personalControlPage} element={<Page
          title={"Saby расчет зарплаты и учет кадров - Управление персоналом"}><PersonalControlPage/></Page>}/>
        <Route path={routes.controlWorkingTime} element={<Page
          title={"Учет и контроль рабочего времени Saby | Система автоматизированного учета рабочего времени сотрудников"}><ControlWorkingTimePage/></Page>}/>
        <Route path={routes.corporatePortalPage} element={<Page
          title={"Saby - Корпоративный портал для сотрудников организации"}><CorporatePortalPage/></Page>}/>
        <Route path={routes.webinarsAndCommunicationsPage}
               element={<Page title={"SABY meet - Видеозвонки для бизнеса"}><WebinarsPage/></Page>}/>
        <Route path={routes.logisticsPage}
               element={<Page title={"Saby - Программа автоматизации транспортной логистики"}><LogisticsPage/></Page>}/>
        <Route path={routes.eplPage}
               element={<Page title={"Saby - Программа для ведения путевых листов"}><EplPage/></Page>}/>
        <Route path={routes.automationBusinessPage}
               element={<Page title={"Saby - Программы для автоматизации бизнеса"}><BusinessAutomationPage/></Page>}/>
        <Route path={routes.kaEdoPage} element={<Page
          title={"Saby - Электронный документооборот (ЭДО) с контрагентами"}><EdoContractors/></Page>}/>
        <Route path={routes.eTrNPage}
               element={<Page title={"Saby - Электронные транспортные накладные (ЭТрН)"}><EtrnPage/></Page>}/>
        <Route path={routes.mchdPage}
               element={<Page title={"Saby - Оформить Машиночитаемую доверенность (МЧД)"}><MchdPage/></Page>}/>
        <Route path={routes.personalEDOPage}
               element={<Page title={"Кадровый электронный документооборот (КЭДО) в сервисе Saby"}><KedoPage/></Page>}/>
        <Route path={routes.marketAndOnlineSalePage} element={<Page
          title={"Saby Розница - Автоматизация магазина, программа для розничной тороговли"}><OnlineMagazinePage/></Page>}/>
        <Route path={routes.internationalEdoPage} element={<Page
          title={"Saby - Международный электронный документооборот (ЭДО)"}><InternationalPage/></Page>}/>
        <Route path={routes.salaryAndPersonnelPage}
               element={<Page title={"Saby - Зарплата и кадры"}><SalaryAndStaffPage/></Page>}/>
        <Route path={routes.militaryRegistrationPage} element={<Page
          title={"Saby - Программа для воинского учета в организации"}><MilitaryAccountingPage/></Page>}/>
        <Route path={routes.kpiAndMotivationPage}
               element={<Page title={"Saby - KPI, мотивация, и геймификация"}><KpiPage/></Page>}/>
        <Route path={routes.recruitmentOfPersonnelPage}
               element={<Page title={"Saby - Программа для подбора персонала"}><RecruitmentPage/></Page>}/>
        <Route path={routes.helpPage} element={<Page title={"Saby - Поддержка пользователей"}><HelpPage/></Page>}/>
        <Route path={routes.cloudTelephonyPage}
               element={<Page title={"Saby - Облачная телефония"}><CloudCalls/></Page>}/>
        <Route path={routes.directionsPage} element={<Page title={"Saby - Продукты"}><DirectionsPage/></Page>}/>


        {/*in development*/}
        <Route path={routes.messengerPage} element={<InDevelopmentPage/>}/>
        <Route path={routes.tariffsPage} element={<InDevelopmentPage/>}/>
        <Route path={routes.marketPage} element={<InDevelopmentPage/>}/>
        <Route path={routes.corpUcPage} element={<InDevelopmentPage/>}/>

      </Routes>
    </Suspense>
  );
};

export default App;

