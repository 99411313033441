import { routes } from "../../../router/routes";

//первая колонка

import reporting from "../../../assets/images/main_icons/reporting.svg";
import accounting from "../../../assets/images/main_icons/accounting.svg";
import edo_business from "../../../assets/images/main_icons/edo_business.svg";
import edo_contractors from "../../../assets/images/main_icons/edo_contractors.svg";
import orders from "../../../assets/images/main_icons/orders.svg";
import business_management from "../../../assets/images/main_icons/business_management.svg";
import international_edo from "../../../assets/images/main_icons/international_edo.svg";
import ecp from "../../../assets/images/main_icons/ecp.svg";
import mchd from "../../../assets/images/main_icons/mchd.svg";
import transport_logistics from "../../../assets/images/main_icons/transport_logistics.svg";
import waybills from "../../../assets/images/main_icons/waybills.svg";
import transportation_documents from "../../../assets/images/main_icons/transportation_documents.svg";
import crm from "../../../assets/images/main_icons/crm.svg";
import telephony from "../../../assets/images/main_icons/telephony.svg";
import vok from "../../../assets/images/main_icons/vok.svg";

//вторая колонка

import ofd from "../../../assets/images/main_icons/ofd.svg"; 
import automation_business from "../../../assets/images/main_icons/automation_business.svg";
import shop from "../../../assets/images/main_icons/shop.svg";
import restaurant from "../../../assets/images/main_icons/restaurant.svg";
import salon from "../../../assets/images/main_icons/salon.svg";
import trade from "../../../assets/images/main_icons/trade.svg";
import mobile_employees from "../../../assets/images/main_icons/mobile_employees.svg";
import video_surveillance from "../../../assets/images/main_icons/video_surveillance.svg";
import government_systems from "../../../assets/images/main_icons/government_systems.svg";
import auction from "../../../assets/images/main_icons/auction.svg";

//третья колонка


import personnel_management from "../../../assets/images/main_icons/personnel_management.svg";
import personnel_edo from "../../../assets/images/main_icons/personnel_edo.svg";
import stuck_up from "../../../assets/images/main_icons/stuck_up.svg";
import military from "../../../assets/images/main_icons/military.svg";
import working_hours from "../../../assets/images/main_icons/working_hours.svg";
import personnel_selection from "../../../assets/images/main_icons/personnel_selection.svg";
import kpi from "../../../assets/images/main_icons/kpi.svg";
import corporate_portal from "../../../assets/images/main_icons/corporate_portal.svg";
import webinars from "../../../assets/images/main_icons/webinars.svg";

/*

*/

export const ForAllData = [
  {
    icon: reporting,
    name: "Отчетность через интернет",
    about:
      "Электронная отчетность во все госорганы: подготовка, проверка, анализ и сдача отчетности",
    link: routes.reportPage,
  },
  {
    icon: accounting,
    name: "Бухгалтерия и учёт",
    about:
      "Программа учета со встроенным ОФД, ЕГАИС, ЭДО и электронной отчетностью",
    link: routes.accountingPage,
  },
  {
    icon: edo_business,
    name: "ЭДО и бизнес-процессы",
    about: "Онлайн-сервис для быстрого создания и обмена любыми документами",
    link: routes.edoPage,
  },
  {
    icon: edo_contractors,
    name: "ЭДО с контрагентами",
    about:
      "Отправляйте и подписывайте акты, счета-фактуры, накладные и другие документы онлайн в 1 клик",
    link: routes.kaEdoPage,
  },
  {
    icon: orders,
    name: "Заказы и поставки (EDI)",
    about:
      "Обмен заказами, прайсами, документами и данными о товарах между заказчиками и поставщиками",
    link: routes.ediPage,
  },
  {
    icon: business_management,
    name: "Управление бизнес-процессами",
    about:
      "Электронные согласования, любые маршруты движения документов, настройка своих процессов по стандартам BPM",
    link: routes.controlBusinessProccessesPage,
  },
  {
    icon: international_edo,
    name: "Международный ЭДО",
    about: "Обмен электронными документами с иностранными партнерами",
    link: routes.internationalEdoPage,
  },
  {
    icon: ecp,
    name: "Электронная подпись",
    about:
      "Сэкономьте время и упростите свою работу с нашей электронной подписью",
    link: routes.ecpPage,
  },
  {
    icon: mchd,
    name: "Машиночитаемые доверенности",
    about:
      "Создание, регистрация в ФНС, контроль и проверка электронных доверенностей",
    link: routes.mchdPage,
  },
  {
    icon: transport_logistics,
    name: "Транспорт и логистика",
    about:
      "Все для управления перевозками на собственном или наемном транспорте",
    link: routes.logisticsPage,
  },
  {
    icon: waybills,
    name: "Электронные путевые листы",
    about:
      "Выпускайте транспорт на линию быстрее, контролируйте поездки онлайн и экономьте время и деньги на оформлении документов",
    link: routes.eplPage,
  },
  {
    icon: transportation_documents,
    name: "ЭТрН и перевозочные документы",
    about:
      "Автоматизируйте обмен перевозочными документами без риска ошибок и потери накладных",
    link: routes.eTrNPage,
  },
  {
    icon: crm,
    name: "CRM",
    about:
      "Со встроенным ЭДО и самой большой базой компаний с контактными данными.",
    link: routes.crmPage,
  },
  {
    icon: telephony,
    name: "Облачная телефония",
    about:
      "Оцифруйте все входящие звонки в клиентскую базу с развернутой аналитикой по вызовам",
    link: routes.cloudTelephonyPage,
  },
  {
    icon: vok,
    name: "Всё о компаниях",
    about: "Всё о компаниях и их владельцах",
    link: routes.aboutCompaniesPage,
  },
];

export const forSpecificBusiness = [
  {
    icon: ofd,
    name: "ОФД",
    about:
      "Онлайн‑передача чеков в налоговую с гибкой аналитикой продаж в приложении",
    link: routes.ofdPage,
  },
  {
    icon: automation_business,
    name: "Автоматизация бизнеса",
    about:
      "Набор облачных решений для бизнеса разных сфер и размеров. Ведите бизнес в одной системе",
    link: routes.automationBusinessPage,
  },
  {
    icon: shop,
    name: "Магазины и онлайн-продажи",
    about: "Автоматизируйте продажи под ключ",
    link: routes.marketAndOnlineSalePage,
  },
  {
    icon: restaurant,
    name: "Рестораны, кафе и столовые",
    about:
      "Увеличение прибыли и повышение уровня обслуживания через автоматизацию процессов",
    link: routes.restaurantCafeBistroPage,
  },
  {
    icon: salon,
    name: "Салоны и сфера услуг",
    about:
      "Онлайн-запись, расписание мастеров, учет материалов, программа лояльности и удобная кассовая программа",
    link: routes.salonAndServiceSectorPage,
  },
  {
    icon: trade,
    name: "Торговля и производство",
    about:
      "А еще — CRM для продаж, учет денег, расчет потребностей и оптимизация запасов, склады на производстве.",
    link: routes.tradeAndProductionPage,
  },
  {
    icon: mobile_employees,
    name: "Мобильные сотрудники",
    about:
      "Автоматизированный онлайн-сервис для управления выездным персоналом",
    link: routes.mobileStaffPage,
  },
  {
    icon: video_surveillance,
    name: "Видеонаблюдение",
    about:
      "Контроль всех офисов и точек продаж с оповещением о подозрительных действиях, видео по чекам и «облачным архивом».",
    link: routes.videoControlPage,
  },
  {
    icon: government_systems,
    name: "Обмен с госсистемами",
    about:
      "Оформляйте документы как обычно, а мы отчитаемся за вас. Работаем со всеми системами маркировки.",
    link: routes.exchangeWithGovPage,
  },
  {
    icon: auction,
    name: "Торги и закупки",
    about: "Первыми узнавайте о новых закупках и участвуйте в победных торгах",
    link: routes.tendersAndPurchasesPage,
  },
];

export const forStaffControl = [
  {
    icon: personnel_management,
    name: "Управление персоналом",
    about:
      "Кадровый учет, расчет зарплаты, подбор персонала. Корпоративный портал и кадровый ЭДО",
    link: routes.personalControlPage,
  },
  {
    icon: personnel_edo,
    name: "Кадровый ЭДО",
    about:
      "Система КЭДО — подписывайте кадровые документы от приема до увольнения. С офисными и удаленными, с самозанятыми и ГПХ",
    link: routes.personalEDOPage,
  },
  {
    icon: stuck_up,
    name: "Зарплата и кадры",
    about:
      "Онлайн-сервис с удобными инструментами для автоматизации кадрового управления и простого расчета любых начислений",
    link: routes.salaryAndPersonnelPage,
  },
  {
    icon: military,
    name: "Воинский учёт",
    about:
      "С сервисом вы сможете удобно организовать реестр военнообязанных в компании, быстро готовить и обновлять документы",
    link: routes.militaryRegistrationPage,
  },
  {
    icon: working_hours,
    name: "Учет рабочего времени",
    about:
      "От электронной проходной и активности за компьютером с учетом больничных, отпусков и отгулов до формирования табеля",
    link: routes.controlWorkingTime,
  },
  {
    icon: personnel_selection,
    name: "Подбор персонала",
    about:
      "Автоматизация рекрутинга на всех этапах: от создания и публикации вакансий до воронки подбора и приема на работу",
    link: routes.recruitmentOfPersonnelPage,
  },
  {
    icon: kpi,
    name: "KPI и мотивация",
    about:
      "Создайте собственную систему поощрения сотрудников с помощью набора интерактивных инструментов.",
    link: routes.kpiAndMotivationPage,
  },
  {
    icon: corporate_portal,
    name: "Корпоративный портал",
    about: "Поортал, который любят соотрудники и HR",
    link: routes.corporatePortalPage,
  },
  {
    icon: webinars,
    name: "Вебинары и коммуникации",
    about: "Saby Meet - Всё для видеокоммуникаций",
    link: routes.webinarsAndCommunicationsPage,
  },
];
