export const partnersData = [
  {
    companyName: "Татнефть",
    reviewText: "В Saby мы автоматизировали обмен документами по согласованию цен с поставщиками, заказами, закрывающими документами. Saby следит, чтобы поставки шли строго по согласованным прайс-листам. Кроме того, Saby упростил работу товароведам: они добавляют товар в заказ из единого каталога для всех поставщиков. Сейчас мы масштабируем систему автозаказа на всю сеть АЗС. Она уже прошла испытания и отлично интегрировалась с Saby.",
    videoID: "v1mNXahEiuAU"
  },

  {
    companyName: "Сеть магазинов Магнит",
    reviewText: "Для КЭДО мы скрупулезно выбирали провайдера, т.к. нуждались не в обычном подписании печатных форм, а в гибкой платформе, поддерживающей внутренние бизнес‑процессы и интеграцию с 1С:ЗУП. Важный фактор: система должна сама выпускать ЭП. Под эти условия идеально подошел СБИС. Мы уже чувствуем, как упростилась кадровая работа.",
    videoID: "vTlPBHcHJQFQ"
  },

  {
    companyName: "Газпром Медиа",
    reviewText: "При выборе провайдера мы руководствовались двумя ключевыми требованиями. Первое: кадровые менеджеры должны оставаться работать в 1С:ЗУП и минимально соприкасаться с платформой КЭДО. Второе: чтобы бизнес‑процессы в системе можно было легко настраивать самим, без посторонней помощи. Из всех вендоров Saby максимально смог решить эти задачи",
    videoID: "vivVVQslkgRA"
  },
]