import React from 'react';
import DirectionCard from "../../ui/DirectionCard/DirectionCard";
import SectionTitle from "../../ui/SectionTitle/SectionTitle";

import styles from "./Directions.module.scss"
import {ForAllData, forSpecificBusiness, forStaffControl} from "./DirectionsData";

const Directions = () => {
  const forAll = Array.from({length: Math.ceil(ForAllData.length / 3)}, (_, index) =>
    ForAllData.slice(index * 3, index * 3 + 3))

  const forSpecific = Array.from({length: Math.ceil(forSpecificBusiness.length / 3)}, (_, index) =>
    forSpecificBusiness.slice(index * 3, index * 3 + 3))

  const forStaff = Array.from({length: Math.ceil(forStaffControl.length / 3)}, (_, index) =>
    forStaffControl.slice(index * 3, index * 3 + 3))

  return (
    <div className={styles.directions}>
      <div className={styles.direction}>
        <SectionTitle title={"Для всех"}/>

        {forAll.map((directions, chunkIndex) => (
          <div className={styles.columns} key={chunkIndex}>
            {directions.map((direction, index) => (
              <div className={styles.column} key={index}>
                <DirectionCard icon = {direction.icon} name={direction.name} about={direction.about} link={direction.link}/>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.direction}>
        <SectionTitle title={"Для автоматизации бизнеса"}/>

        {forSpecific.map((directions, chunkIndex) => (
          <div className={styles.columns} key={chunkIndex}>
            {directions.map((direction, index) => (
              <div className={styles.column} key={index}>
                <DirectionCard icon = {direction.icon} name={direction.name} about={direction.about} link={direction.link}/>
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className={styles.direction}>
        <SectionTitle title={"Для автоматизации кадров"}/>

        {forStaff.map((directions, chunkIndex) => (
          <div className={styles.columns} key={chunkIndex}>
            {directions.map((direction, index) => (
              <div className={styles.column} key={index}>
                <DirectionCard icon = {direction.icon} name={direction.name} about={direction.about} link={direction.link}/>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Directions;