import React, {Suspense} from 'react';

import styles from "./StartSectionLayout.module.scss"

interface IProps {
  children: React.ReactNode
}

const StartSectionLayout = ({children}: IProps) => {
  return (
    <section className={styles.container + " " + styles.firstSection}>
      <Suspense>
        {children}
      </Suspense>
    </section>
  );
};

export default StartSectionLayout;