import React, {lazy, useState} from 'react';

import styles from './Header.module.scss'
import {Link} from 'react-router-dom';

import logoImage from "../../../assets/images/logos/logo.svg"
import {NavItems} from './NavItems'
import MenuIcon from '@mui/icons-material/Menu';

const CloseIcon = lazy(() => import('@mui/icons-material/Close'))

const Header = () => {
  const [isBurgerOpened, setIsBurgerOpened] = useState(false)

  return (
    <header className={styles.header}>
      <div className={styles.headerWrapper}>

        <Link to="/"><img src={logoImage} alt="Логотип" className={styles.logo}/></Link>

        <nav>
          <ul className={styles.navItems}>
            {NavItems.map((item, index) => (
              <li className={styles.navItem}>
                <Link to={item.linkTo} key={index}>{item.text}</Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.headerContacts}>
          <Link to='tel:79061006010'>+7 906 100-60-10</Link>
        </div>

        <div className={isBurgerOpened ? styles.hideIcon : styles.burgerIcon}>
          <MenuIcon onClick={() => setIsBurgerOpened(true)}/>
        </div>
      </div>

      {isBurgerOpened &&
          <div className={styles.burger}>
              <div className={isBurgerOpened ? styles.displayCloseIcon : styles.hideIcon}>
                  <CloseIcon onClick={() => setIsBurgerOpened(false)}/>
              </div>

              <nav className={styles.burgerNav}>
                  <ul className={styles.navItems}>
                    {NavItems.map((item, index) => (
                      <li className={styles.navItem}>
                        <Link to={item.linkTo} key={index}>{item.text}</Link>
                      </li>
                    ))}
                  </ul>
              </nav>
          </div>
      }
    </header>
  );
};

export default Header;