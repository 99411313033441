import React from 'react';
import Directions from "../../components/blocks/Directions/Directions";
import JoinUs from "../../components/blocks/JoinUs/JoinUs";
import Partners from "../../components/blocks/Partners/Partners";
import Footer from "../../components/ui/Footer/Footer";
import Header from "../../components/ui/Header/Header";
import SectionLayout from "../../layouts/SectionLayout/SectionLayout";
import StartSectionLayout from "../../layouts/StartSectionLayout/StartSectionLayout";

const DirectionsPage = () => {
  return (
    <div>
      <Header/>

      <StartSectionLayout>
        <Directions/>
      </StartSectionLayout>

      <SectionLayout><Partners/></SectionLayout>
      <SectionLayout><JoinUs/></SectionLayout>
      <SectionLayout><Footer/></SectionLayout>
    </div>
  );
};

export default DirectionsPage;